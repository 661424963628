.bottom__sheet__market__price__slider {
  animation: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.6s linear;
  max-width: 100%;
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.slider__action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #ffffff;
  opacity: 0.8;
  border: 1px solid #ededed;
  border-radius: 8px;
  cursor: pointer;
}

.bottom__sheet__market__price__slider_button__text {
  font-size: 14px;
  font-weight: 600;
  color: #262626;
  margin-top: 5px;
}

.bottom__sheet__market__price__slider_label {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #757575;
}

.slider {
  width: 100% !important;
  height: 8px !important;
  border-radius: 8px !important;
  color: #000000 !important;
  // background-image: url("../../assets/icons/ProboSliderIcon.svg") !important;

  span:first-child {
    // background-color: #f0f3f2 !important;
    // opacity: 1;
  }

  span:nth-child(3) {
    box-shadow: none !important;
  }
}

.order__place__button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 591px;
  margin: 20px auto 0px;
}

.order__place__button__disabled {
  background: #cccccc !important;

  &:hover,
  &:focus,
  &:active {
    background: #cccccc !important;
    cursor: not-allowed;
  }
}

.balance__and__commission__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 16px;
  border-radius: 0px 0px 4px 4px;
  background-color: #ededed;
  color: #3f3f3f;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin: 20px -21px -14px -21px;
}

.low__balance__banner {
  display: flex;
  margin-top: 20px;
}

.low__balance__banner__info {
  display: flex;
  align-items: center;
  margin-left: -6px;
}

.low__balance__banner__icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.low__balance__banner__info__wrapper {
}

.low__balance__banner__title {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.low__balance__banner__sub__title {
  font-weight: 500;
  font-size: 0.65rem;
}

.low__balance__banner__cta {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.low__balance__banner__cta__btn {
  background: #fccfcf !important;
  color: #c00b0b !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  padding: 8px 31px !important;
  border-radius: 4px !important;
}

// Mobile Devices (420px and up)
@media (min-width: 420px) {
}

// Mobile Devices (480px and up)
@media (min-width: 480px) {
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
