@import './variables.scss';

// Montserrat
@font-face {
  font-family: Montserrat;
  font-weight: 100;
  src: url('../assets/fonts/montserrat/Montserrat-Thin.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 200;
  src: url('../assets/fonts/montserrat/Montserrat-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 300;
  src: url('../assets/fonts/montserrat/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 400;
  src: url('../assets/fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  src: url('../assets/fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 600;
  src: url('../assets/fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-weight: 700;
  src: url('../assets/fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat !important;
}

html {
  font-size: 16px;
}

:root {
  --safe-area-top: env(safe-area-inset-top);
  --safe-area-bottom: env(safe-area-inset-bottom);
  --safe-area-left: env(safe-area-inset-left);
  --safe-area-right: env(safe-area-inset-right);
}

.mobile__layout {
  margin: auto;
  background-color: $layout__background__color;
}

.app__layout {
  display: flex;
  flex-direction: column;
}

.hidden {
  display: none;
}

.visibility__hidden {
  visibility: hidden;
}

.toast__message {
  min-height: 2.75rem;
  font-size: 0.75rem;
  font-family: inherit;
  line-height: 1.25rem;
  margin: 0 1rem 3rem !important;
  border-radius: 8px !important;

  button {
    color: #fff !important;
    opacity: 0.9 !important;
  }
}

.use__app {
  background-color: #ffffff;
  color: #262626;
  border: 1px solid #ededed;
  border-radius: 8px;
  padding: 8px 10px;
  font-size: 12px;
  cursor: pointer;
}

#get__app {
  background-color: #262626;
  width: 100%;
  color: #ffffff;
  padding: 15px 20px;
  border: 1px solid #262626;
  border-radius: 0.8rem;
  font-size: 16px;
  cursor: pointer;
}

#get__app:hover {
  background-color: #262626;
}

.app__popup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  padding: 10px 2px;
  z-index: 100;
  font-size: 12px;
  width: 100%;
  max-width: 420px;
  bottom: 70px;
  background-color: #262626;
  box-shadow: 0px -1px 8px 2px $bottom__navigation__box__shaddow;
  color: #ffffff;
}

.bottom__navigation {
  position: fixed;
  z-index: 99;
  width: 100%;
  bottom: 0;
  box-shadow: 0px -1px 8px 2px $bottom__navigation__box__shaddow;

  .Mui-selected {
    color: $bottom__navigation__icon__color !important;
  }
}

.bottom__navigation__mpl {
  .Mui-selected {
    color: $bottom__navigation__icon__color__black !important;
  }
}

.recharts-legend-wrapper {
  padding-top: 1rem !important;
}

// .MuiTabs-indicator {
//   background-color: #262626 !important;
// }

.partner__layout {
  position: relative;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  padding: 0.75rem 0.75rem 0 0.75rem;
  position: relative;
  border: 1px solid #cccccc;
  height: 250px;
}

.carousel .control-dots {
  z-index: 1 !important;
  margin: 0;
}

.dot {
  width: 4px !important;
  height: 4px !important;
  border-radius: 8px !important;
  margin: 0 2px !important;
  box-shadow: none !important;
  background: #757575 !important;
}

.slider-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}

// Mobile Devices (380px and up)
@media (min-width: 350px) {
  .app__popup {
    font-size: 14px;
    padding: 10px 5px;
  }
  .use__app {
    font-size: 14px;
  }
}

@media (min-width: 370px) {
  .app__popup {
    font-size: 16px;
    padding: 10px 10px;
  }
  .use__app {
    font-size: 16px;
  }
}

// Mobile Devices (420px and up)
@media (min-width: 420px) {
}

// Mobile Devices (480px and up)
@media (min-width: 480px) {
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// #seo-metadata {
//   background-image: url("../assets/backgrounds/seo.png");
//   background-repeat: no-repeat;
//   background-size:100% 100%;
// }
