.event__card__wrapper {
  * {
    font-family: 'Montserrat' !important;
  }
}

.event__card {
  border-radius: 0.25rem !important;
  overflow: hidden;
}

.event__card__probability {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #0066ff;
  margin-top: 30px;
}

.event__card__one__liner__name {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  margin-top: 2px;
}

.event__card__actions__button__view {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 26px;
}

.event__card__actions__button {
  padding: 0.5rem 1rem !important;
  height: 2.25rem !important;
  border-radius: 0.25rem !important;

  font-weight: 600 !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  z-index: 1;
  width: 171px;

  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.event__card__actions__button__yes {
  background: #0066ff !important;
  color: #ffffff !important;
}

.event__card__actions__button__no {
  background: #cd0000 !important;
  color: #ffffff !important;
}

// Mobile Devices (420px and up)
@media (min-width: 420px) {
  .event__card__info__tag__name {
    font-size: 0.65rem;
  }

  .event__card__actions__button {
    font-size: 0.8rem !important;
  }

  .event__card__actions__button {
    height: 2.5rem !important;
  }
}

// Mobile Devices (480px and up)
@media (min-width: 480px) {
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
