.partner__header__wrapper {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-end;
  margin-bottom: 20px;
}

.partner__header__title {
  font-size: 16px;
  line-height: 24px;
}

.event__card__info__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 19px;
  position: relative;
}

.event__card__info__tags {
  display: flex;
}

.event__card__info__tag__icon {
  width: 18px;
  height: 18px;
  object-fit: cover;
}

.event__card__info__tag__name {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin-left: 3px;
  color: #545454;
}

.event__card__info__name {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #3f3f3f;
  text-align: left;
  margin-top: 15px;
}

.event__card__info__image {
  width: 5rem;
  height: 5rem;
  border-radius: 0.5rem;
  margin-left: auto;
  object-fit: cover;
  position: relative;
}

// Mobile Devices (420px and up)
@media (min-width: 420px) {
}

// Mobile Devices (480px and up)
@media (min-width: 480px) {
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
