.partner__welcome__wrapper {
  min-height: 180px;
}

.partner__order__placed__image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
}

.partner__login__msg {
  text-align: center;
  color: #0f0f0f;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  margin-top: 11px;
}

.heading {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.subheading {
  font-size: 12px;
  line-height: 16px;
}

.partner__hyperlinks {
  width: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px 8px;
  margin-top: 26px;
  gap: 7px;
}

.partner__hyperlinks__for__one__data {
  justify-content: center;
}

.partner__hyperlinks__btn {
  flex: 0 45%;
  background: #ffffff;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: #262626;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid #ededed;
}
